import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import useSite from '../hooks/use-site'

// Index page should be a simple page with a link that redirect to Bulletin

const IndexPage = ({ data }) => {
  // Use the metadata hook
  const site = useSite()
  // console.log(site.title)

  // Pass data to the SEO component:
  // <SEO title={site.title} description={site.description} />

  return (
    <Layout>
      <SEO title={site.title} description={site.description} />
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </Layout>
  )
}

export default IndexPage
